<script setup lang="ts">
import { useUser } from '@lasso/shared/hooks'

import { useInitApi } from '@admin/app/useInitApi'
import { useInitLuikit } from '@admin/app/useInitLuikit'
import { useInitRouter } from '@admin/app/useInitRouter'

useInitLuikit()
useInitApi()
useInitRouter()

const { isLoggedIn } = useUser()
</script>

<template>
  <RouterView v-slot="slotProps">
    <component :is="slotProps.Component" v-if="isLoggedIn" />
  </RouterView>
</template>

<style>
@tailwind utilities;
</style>
