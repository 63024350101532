import { createPinia } from 'pinia'
import singleSpaVue from 'single-spa-vue'
import { App as VueApp, createApp, h } from 'vue'

import { appUnmountCleanupPlugin, errorHandlerPlugin } from '@lasso/shared/app'

import App from './App.vue'
import { createRouter } from './router'

export const { bootstrap, mount, unmount } = singleSpaVue({
  createApp,
  appOptions: {
    render: () => h(App),
  },
  handleInstance(app: VueApp) {
    app.use(createRouter()).use(createPinia()).use(errorHandlerPlugin).use(appUnmountCleanupPlugin)
  },
})
