import { RouteRecordRaw } from 'vue-router'

import { ViewFeatures } from '@admin/components/ViewFeatures'
import { ViewRoles } from '@admin/components/ViewRoles'

import { RouteName } from './types'

export const rootRoute: RouteRecordRaw = {
  path: '/app/admin',
  name: RouteName.Root,
  redirect: { name: RouteName.Features },
  children: [
    {
      path: 'features',
      name: RouteName.Features,
      component: ViewFeatures,
      meta: {
        title: 'Feature Preview',
      },
    },
    {
      path: 'roles',
      name: RouteName.Roles,
      component: ViewRoles,
      meta: {
        title: 'Roles Management',
      },
    },
  ],
}
