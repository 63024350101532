import { useRoute } from 'vue-router'

import { useProvideBreadcrumbs } from '@lasso/luikit'
import { useInitRouterRedirect } from '@lasso/shared/app'
import { useProvideTitle, useUser } from '@lasso/shared/hooks'

import { useAccessControl } from '@admin/hooks/useAccessControl'

export const useInitRouter = () => {
  const route = useRoute()
  const { user } = useUser()
  const accessControl = useAccessControl()

  useInitRouterRedirect((to) => {
    if (!user.value || !accessControl.permissions.adminOnly || !accessControl.features.adminPagesV2) {
      return false
    }

    return to.meta.allowed?.(accessControl, user.value) ?? true
  })
  useProvideTitle(() => route.meta.head?.title ?? route.meta.title, { baseTitle: 'Admin' })
  useProvideBreadcrumbs(() => route.meta.breadcrumbs)
}
