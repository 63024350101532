import { AxiosInstance } from 'axios'

import { bindApiMethods } from '@lasso/api-shared'
import { useApiCache } from '@lasso/shared/hooks'
import { createInjectionState } from '@lasso/shared/utils'

import { AdminApi } from '../api'

export const [useProvideAdminApi, useAdminApi] = createInjectionState(
  (axiosInstance: AxiosInstance) => {
    const [api, methodsMapping] = bindApiMethods(new AdminApi(axiosInstance))

    useApiCache().aliasCacheKeys(methodsMapping)

    return api
  },
  { throwWhenNotProvided: 'Admin API was not provided' },
)
